import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UserService} from "../service/api/user.service";
import {LoginRequest} from "../model/requests";
import {FormControl, FormGroup, Validators, ReactiveFormsModule} from "@angular/forms";
import {SocialAuthService, GoogleSigninButtonModule} from '@abacritt/angularx-social-login';
import {LoginService} from "../service/login.service";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {MatDividerModule} from '@angular/material/divider';
import {MatButtonModule} from '@angular/material/button';
import {NgIf} from '@angular/common';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';

@Component({
    selector: 'cb-login',
    template: `
        <div class="column center align-center" style="padding: 32px; padding-top: 0">

            <h1 i18n>Login</h1>

            <form [formGroup]="form" class="login-form">

                <mat-form-field class="login-form-full-width" appearance="fill">
                    <mat-label i18n>Email</mat-label>
                    <input type="username" matInput value="" name="username" formControlName="username"
                           (input)="loginError = undefined">
                    <mat-error *ngIf="form.get('username')?.hasError('required')" i18n>
                        Email or username is required.
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="login-form-full-width" appearance="fill">
                    <mat-label i18n>Password</mat-label>
                    <input type="password" matInput value="" name="password" formControlName="password"
                           (input)="loginError = undefined">
                    <mat-error *ngIf="form.get('email')?.hasError('required')" i18n>
                        Password is required
                    </mat-error>
                </mat-form-field>
                <mat-error *ngIf="loginError">
                    {{ loginError }}
                </mat-error>

                <span class="row end">
                    <a routerLink="/auth/forgot-password" i18n>Forgot Password?</a>
                </span>

                <button mat-raised-button color="primary" style="width: 100%; max-width: 300px; margin-top: 16px"
                        (click)="login()" i18n>Sign in</button>
            </form>

            <div class="divider row center align-center" style="width: 100%; height: 48px">
                <mat-divider style="width: 100%"></mat-divider>
                <span style="padding: 0 10px" i18n>or</span>
                <mat-divider style="width: 100%"></mat-divider>
            </div>

            <ng-container *ngIf="!isAndroid() && !hasAndroidLogin()">
                <asl-google-signin-button type="standard" size="large" text="signin_with" width="300px"
                                          logo_alignment="center"></asl-google-signin-button>
            </ng-container>
            <ng-container *ngIf="hasAndroidLogin()">
                <button mat-raised-button color="primary" style="width: 100%; max-width: 300px; margin-top: 16px"
                        (click)="startAndroidLogin()">
                    <img class="google-logo" src="assets/google.svg" alt="google"/>
                    <span i18n>Sign in with Google</span>
                </button>
            </ng-container>

            <br>
            <mat-divider style="width: 100%; max-width: 350px"></mat-divider>

            <br>
            <p class="mat-body-1" i18n>
                Not a member? <a routerLink="/auth/register">Register now</a>
            </p>
        </div>
    `,
    styles: [`
        .login-form {
            min-width: 150px;
            max-width: 300px;
            width: 100%;
        }

        .login-form-full-width {
            width: 100%;
        }

        .google-logo {
            width: 18px;
            height: 18px;
            margin-bottom: -2px;
            margin-right: 10px;
            object-fit: cover;
        }
    `],
    standalone: true,
    imports: [ReactiveFormsModule, MatFormFieldModule, MatInputModule, NgIf, RouterLink, MatButtonModule, MatDividerModule, GoogleSigninButtonModule]
})

export class LoginComponent implements OnInit {

    form = new FormGroup({
        username: new FormControl(undefined, [Validators.required]),
        password: new FormControl(undefined, [Validators.required])
    });

    loginError?: string;

    @Output()
    loggedIn = new EventEmitter<boolean>();

    constructor(
        private loginService: LoginService,
        private userService: UserService
    ) {
    }

    ngOnInit(): void {
        this.loginService.subscribeToSocialLogin()
    }

    login() {
        if (!this.form.valid) {
            Object.keys(this.form.controls).forEach(key => {
                this.form.get(key)?.markAsTouched({onlySelf: true});
            });
            return;
        }

        let username = this.form.get('username')?.value;
        let password = this.form.get('password')?.value;

        if (username && password) {
            let loginRequest: LoginRequest = {
                username: username,
                password: password
            };
            this.userService.login(loginRequest).subscribe({
                next: response => {
                    this.loginService.onLogin(response).subscribe(() => {
                        this.loggedIn.emit(true);
                    })
                },
                error: error => {
                    if (error.error?.message) {
                        this.loginError = error.error.message;
                    } else {
                        this.loginError = $localize`An error occurred while logging in. Please try again later.`;
                    }
                }
            });
        }
    }

    isAndroid(): boolean {
        return (window as any).Android
    }

    hasAndroidLogin(): boolean {
        return (window as any).Android && (window as any).Android.googlePlayLogin
    }

    startAndroidLogin() {
        // Prevent form from displaying errors
        this.form?.get('username')?.markAsUntouched({onlySelf: true});
        (window as any).Android.googlePlayLogin()
    }
}
